import { api } from '@/const/axiosApi'

export default {
  namespaced: true,

  state: {
    bonuses: [],
    bonusLogs: [],
    bonusLogsMeta: {},
  },

  actions: {
    async getBonuses({ commit, dispatch }) {
      try {
        const { data } = await api.get(`/api/v1/bonus-accounts`)
        commit('SET_BONUSES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async getUserBonuses({ commit, dispatch }, userId) {
      try {
        const { data } = await api.get(`/api/v1/bonus-accounts/user/${userId}`)
        commit('SET_BONUSES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async addUserBonuses({ dispatch }, payload) {
      const { userId, amount, company, scope = 'local' } = payload
      try {
        await api.post(`/api/v1/companies/${company}/bonus-accounts/${userId}/${scope}`, { amount })
        dispatch('logs/displaySuccessAlert', { message: `Успішно додано ${amount} бонусів` }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async getBonusLogs({ commit, dispatch }, { companyId, userId, page = 1, limit = 10, sort, createdAt } = {}) {
      const url = userId ? `/api/v1/companies/${companyId}/bonus-account-history/${userId}` : `/api/v1/bonus-account-history`
      let query = { page, limit }
      const optionalParams = { sort, createdAt }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_PROFILE_BONUS_LOGS', data.data)
        commit('SET_PROFILE_BONUS_LOGS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_BONUSES(state, bonuses) {
      state.bonuses = bonuses
    },
    SET_PROFILE_BONUS_LOGS(state, logs) {
      state.bonusLogs = logs
    },
    SET_PROFILE_BONUS_LOGS_META(state, meta) {
      state.bonusLogsMeta = meta
    },
  },
}
