import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    servicePoints: [],
    servicePointsMeta: {},
    servicePoint: {},
    createdServicePoint: {},
    servicePointsShortInfo: {},
  },
  actions: {
    async loadServicePoints(
      { commit, dispatch },
      { company, page = 1, limit = 10, sort, status, forSearch = 0, ...restFilterParams } = {}
    ) {
      const url = forSearch
        ? `/api/v1/companies/${company}/servicepoints/forSearch`
        : `/api/v1/companies/${company}/servicepoints`
      let query = { page, limit }
      const optionalParams = { sort, status, ...restFilterParams }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_ALL_SERVICE_POINTS', data.data)
        commit('SET_ALL_SERVICE_POINTS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedServicePoint({ commit, dispatch }, payload) {
      try {
        dispatch('logs/displayOverlayLoader', true, { root: true })
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/servicepoints/${payload.servicePointId}`)
        commit('SET_SELECTED_SERVICE_POINT', data.data)
        dispatch('logs/displayOverlayLoader', false, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewServicePoint({ commit, dispatch }, payload) {
      try {
        const { data } = await api.post(`/api/v1/companies/${payload.companyId}/servicepoints`, payload.newServicePoint)
        await dispatch('logs/displaySuccessAlert', { message: 'Пункт обслуговування створено успiшно!' }, { root: true })
        commit('SET_CREATED_SERVICE_POINT', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedServicePoint({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/servicepoints/${payload.servicePointId}`,
          payload.updatedServicePoint
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Пункт обслуговування оновлено успiшно!' }, { root: true })
        commit('SET_UPDATED_SERVICE_POINT', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedServicePoint({ commit, dispatch }, payload) {
      try {
        await api.delete(`/api/v1/companies/${payload.companyId}/servicepoints/${payload.servicePointId}`)
        await dispatch('logs/displayInfoAlert', { message: 'Пункт обслуговування видалено!' }, { root: true })
        commit('SET_DELETED_SERVICE_POINT')
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async addTerminalToServicePoint({ dispatch }, payload) {
      try {
        await api.post(
          `/api/v1/companies/${payload.companyId}/servicepoints/${payload.servicePointId}/add-terminal/${payload.terminalId}`
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Термiнал пiдключено успiшно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async removeTerminalFromServicePoint({ dispatch }, payload) {
      try {
        await api.delete(
          `/api/v1/companies/${payload.companyId}/servicepoints/${payload.servicePointId}/remove-terminal/${payload.terminalId}`
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Термiнал вiдключено успiшно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async changeServicePointSortingNumber({ dispatch }, payload) {
      try {
        const url = `/api/v1/companies/${payload.companyId}/servicepoints/${payload.id}/update-sort-number`
        const params = { 'sort-number': payload.sortNumber }
        await api.patch(url, null, { params })
        await dispatch('logs/displaySuccessAlert', { message: 'Порядок сортування змінено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async getPointsShortInfo({ commit, dispatch }, { company, ...rest }) {
      const url = `/api/v1/companies/${company}/servicepoints/short-info`
      let query = {}
      const optionalParams = { ...rest }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_SERVICE_POINTS_SHORT_INFO', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ALL_SERVICE_POINTS(state, val) {
      state.servicePoints = val
    },
    SET_ALL_SERVICE_POINTS_META(state, meta) {
      state.servicePointsMeta = meta
    },
    SET_SELECTED_SERVICE_POINT(state, servicePoint) {
      state.servicePoint = servicePoint
    },
    SET_CREATED_SERVICE_POINT(state, servicePoint) {
      state.createdServicePoint = servicePoint
    },
    SET_UPDATED_SERVICE_POINT(state, updatedServicePoint) {
      state.servicePoint = updatedServicePoint
    },
    SET_DELETED_SERVICE_POINT(state, servicePoint) {
      console.log(state.servicePoint)
      console.log(servicePoint)
    },
    SET_SERVICE_POINTS_SHORT_INFO(state, info) {
      state.servicePointsShortInfo = info
    },
  },
  getters: {
    getServicePointsTotalCount(state) {
      return state.servicePointsMeta.total_count
    },
    getCreatedServicePointId(state) {
      return state.createdServicePoint.id || null
    },
  },
}
