import { api } from '@/const/axiosApi'

export default {
  namespaced: true,

  state: {
    firmwares: [],
    firmwaresMeta: {},
    firmwaresForSearch: [],
    firmware: {},
    firmwareOldVersions: [],
    installingFirmware: {},
    installingFirmwares: [],
  },

  actions: {
    async loadFirmwares({ commit, dispatch }, { company, page = 1, limit = 10, sort, search, forSearch = 0, ...rest } = {}) {
      const url = forSearch ? `/api/v1/companies/${company}/firmware/forSearch` : `/api/v1/companies/${company}/firmware`
      let query = { page, limit }
      const optionalParams = { sort, search, ...rest }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit(forSearch ? 'SET_FIRMWARES_FOR_SEARCH' : 'SET_FIRMWARES', data.data)
        !forSearch && commit('SET_FIRMWARES_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedFirmware({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/firmware/${payload.firmwareId}`)
        commit('SET_SELECTED_FIRMWARE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewFirmware({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.companyId}/firmware/`, payload.newFirmware)
        await dispatch('logs/displaySuccessAlert', { message: 'ПЗ для пристрою створено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedFirmware({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(
          `/api/v1/companies/${payload.companyId}/firmware/${payload.firmwareId}`,
          payload.updatedFirmware
        )
        await dispatch('logs/displaySuccessAlert', { message: 'Іформацію про ПЗ оновлено успішно!' }, { root: true })
        commit('SET_UPDATED_FIRMWARE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async uploadFirmwareFile({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.companyId}/firmware/${payload.firmwareId}/upload`, payload.formData)
        await dispatch('logs/displaySuccessAlert', { message: 'ПЗ завантажено успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedFirmware({ dispatch }, payload) {
      try {
        await api.delete(`/api/v1/companies/${payload.companyId}/firmware/${payload.firmwareId}/delete`)
        await dispatch('logs/displaySuccessAlert', { message: 'ПЗ видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async upgradeFirmware({ commit, dispatch }, { companyId, firmware, terminals, sendRequest = true } = {}) {
      try {
        if (sendRequest) {
          const params = { terminals: terminals.map((item) => item.id).join(',') }
          await api.put(`/api/v1/companies/${companyId}/firmware/${firmware.id}/upgrade-offer`, null, { params })
          await dispatch('logs/displaySuccessAlert', { message: 'ПЗ успішно відправлено для встановлення' }, { root: true })
        }
        commit('ADD_INSTALLING_FIRMWARE', { firmware, terminals })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    deleteInstallingFirmware({ commit }, firmwareId) {
      commit('DELETE_INSTALLING_FIRMWARE', { firmwareId })
    },
    async loadFirmwareOldVersions({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`api/v1/companies/${payload.companyId}/firmware/terminal/${payload.terminalId}`)
        commit('SET_FIRMWARE_OLD_VERSIONS', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_FIRMWARES(state, val) {
      state.firmwares = val
    },
    SET_FIRMWARES_META(state, val) {
      state.firmwaresMeta = val
    },
    SET_FIRMWARES_FOR_SEARCH(state, val) {
      state.firmwaresForSearch = val
    },
    SET_SELECTED_FIRMWARE(state, val) {
      state.firmware = val
    },
    SET_UPDATED_FIRMWARE(state, val) {
      state.firmware = val || {}
    },
    SET_FIRMWARE_OLD_VERSIONS(state, val) {
      state.firmwareOldVersions = val
    },
    ADD_INSTALLING_FIRMWARE(state, val) {
      state.installingFirmware = val
      state.installingFirmwares.push(val)
    },
    DELETE_INSTALLING_FIRMWARE(state, data) {
      const index = state.installingFirmwares.findIndex((item) => (item.firmware.id = data.firmwareId))
      if (index > -1) {
        state.installingFirmwares.splice(index, 1)
      }
    },
  },

  getters: {
    getFirmwaresTotalCount(state) {
      return state.firmwaresMeta.total_count
    },
  },
}
