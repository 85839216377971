import { mapActions, mapState } from 'vuex'
import { PROGRESS_DATA_SCHEMA } from '@/const/apiSchemas'
import CentrifugeApi from '@/services/CentrifugeApi'

export default {
  data() {
    return {
      firmwaresInProgress: [],
      ws: null,
      firmwareSubscription: null,
    }
  },

  async created() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.match(/^firmware_.*/)) {
        const firmware = JSON.parse(localStorage.getItem(key))
        await this.upgradeFirmware({ ...firmware, sendRequest: false })
      }
    }
  },

  computed: {
    ...mapState('firmwares', ['installingFirmware', 'installingFirmwares']),
    ...mapState('logs', ['progressData']),
  },

  watch: {
    async installingFirmware(val) {
      localStorage.setItem(`firmware_${val.firmware.id}`, JSON.stringify(val))
      for (const terminal of val.terminals) {
        const firmwareData = val.firmware
        const address = terminal.service_point ? terminal.service_point.address : null
        const payload = {
          ...PROGRESS_DATA_SCHEMA,
          title:
            `<small>Встановлення</small> ПЗ ${firmwareData.version_name} (ID${firmwareData.id})<small> на:</small>` +
            `<br>&nbsp;&nbsp;&nbsp;&nbsp;T ${terminal.id} (${terminal.imei})` +
            `<br>&nbsp;&nbsp;&nbsp;&nbsp;${terminal.service_point ? terminal.service_point.name : '<ТТ невідома>'}` +
            `<br>&nbsp;&nbsp;&nbsp;&nbsp;${address ? `м.${address.city} ${address.street}, ${address.building_number}` : ''}`,
          status: 1,
          statusMessage: 'В процесі...',
          payload: { firmware: firmwareData, terminal },
        }
        const progressId = await this.addProgressData(payload)
        if (progressId) {
          const progressItem = { progressId, firmwareId: firmwareData.id, terminal: terminal.id, imei: terminal.imei }
          this.firmwaresInProgress.push(progressItem)
          await this.connectToWs(progressItem)
        }
      }
    },
  },

  methods: {
    ...mapActions('logs', ['addProgressData', 'updateProgressItem']),
    ...mapActions('firmwares', ['upgradeFirmware', 'deleteInstallingFirmware']),

    async connectToWs({ progressId, firmwareId, imei }) {
      const progressItem = this.progressData.find((item) => item.id === progressId)
      const progressIndex = this.progressData.findIndex((item) => item.id === progressId)
      if (progressItem) {
        this.ws = new CentrifugeApi()
        await this.ws.connectToCentrifuge()
        this.firmwareSubscription = this.ws.centrifuge.newSubscription(`firmware_${firmwareId}_terminal_${imei}`)
        this.firmwareSubscription.on('publication', async (ctx) => {
          if (ctx.data) {
            const percents = +ctx.data.progress
            progressItem.percents = percents
            if (percents < 100) {
              progressItem.status = 1
              progressItem.statusMessage = 'В процесі...'
            } else {
              progressItem.status = 2
              progressItem.statusMessage = 'Завершено'
              progressItem.visible = true
              if (progressIndex > -1) {
                this.firmwaresInProgress.splice(progressIndex, 1)
                await this.deleteInstallingFirmware(firmwareId)
                localStorage.removeItem(`firmware_${firmwareId}`)
              }
            }
            await this.updateProgressItem(progressItem)
          }
        })
        this.firmwareSubscription.subscribe()
      }
    },
  },
}
