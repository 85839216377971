import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    staff: [],
    staffMeta: {},
    employee: {},
  },
  actions: {
    async loadStaff(
      { commit, dispatch },
      { company, page = 1, limit = 10, sort, search, status, forSearch = 0, ...restFilterParams } = {}
    ) {
      const url = forSearch ? `/api/v1/companies/${company}/staff/forSearch` : `/api/v1/companies/${company}/staff`
      let query = { page, limit }
      const optionalParams = { sort, search, status, ...restFilterParams }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_STAFF', data.data)
        commit('SET_STAFF_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadSelectedEmployee({ commit, dispatch }, payload) {
      try {
        const { data } = await api.get(`/api/v1/companies/${payload.companyId}/staff/${payload.staffId}`)
        commit('SET_SELECTED_EMPLOYEE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async createNewEmployee({ dispatch }, payload) {
      try {
        await api.post(`/api/v1/companies/${payload.companyId}/staff`, payload.newEmployee)
        await dispatch('logs/displaySuccessAlert', { message: 'Користувача додано успішно!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async updateSelectedEmployee({ commit, dispatch }, payload) {
      try {
        const { data } = await api.put(`/api/v1/companies/${payload.companyId}/staff/${payload.staffId}`, payload.updatedEmployee)
        await dispatch('logs/displaySuccessAlert', { message: 'Користувача оновлено успішно!' }, { root: true })
        commit('SET_SELECTED_EMPLOYEE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async deleteSelectedEmployee({ dispatch }, payload) {
      try {
        await api.delete(`/api/v1/companies/${payload.companyId}/staff/${payload.staffId}`)
        await dispatch('logs/displayInfoAlert', { message: 'Користувача видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ALL_STAFF_NO_FILTER(state, val) {
      state.allStaffNoFilter = val
    },
    SET_STAFF(state, val) {
      state.staff = val
    },
    SET_STAFF_META(state, staffMeta) {
      state.staffMeta = staffMeta || {}
    },
    SET_SELECTED_EMPLOYEE(state, employee) {
      state.employee = employee || {}
    },
  },
  getters: {
    getStaffTotalCount(state) {
      return state.staffMeta.total_count
    },
  },
}
